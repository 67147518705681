import React from "react"
import { Container } from "react-bootstrap"
import "./QuoteForm.scss"
function QuoteForm() {
    return (
        <div className="quote-form">
            <div className="form-group">
                <label>What is your name?</label>
                <input type={`text`} name={`name`} placeholder={`Your Name`} />
            </div>
            <div className="form-group">
                <label>What is your email?</label>
                <input type={`email`} name={`email`} placeholder={`Your Name`} />
            </div>
            <div className="form-group">
                <label>What is your Telephone number?</label>
                <input type={`text`} name={`telephone`} placeholder={`Your telephone`} />
            </div>
            <div className="quote-ap-text">
                <h3>About your Application</h3>
                <p>This helps give you a more accurate quote and to check your eligibility</p>
            </div>
        </div>
    )
}

export default QuoteForm