import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import tickImg from "../images/banner-tick.svg"
import "../components/BannerIntro/BannerIntro.scss"
import QuoteForm from "../components/QuoteForm/QuoteForm";

const ThanyouQuote = (props) => {
return (
<React.Fragment>
<Header />
<div className="section-p banner-into-wrapper">
    <Container className="banner-container">
        <div className="content">
            <h4 className="heading">YOUR QUOTE</h4>
            <h1 className="title">Accurate quote and eligibility check</h1>
            <div className="description"><p>To provide you with a final price and confirm that you are eligable for the Guarantee please answer the questions .</p></div>       
        </div>
        <QuoteForm />

    </Container>
    </div>
    <Footer />
</React.Fragment>
)
}
export default ThanyouQuote;

